const AppConfig = {
  global: {
    themeColor: "#0A6FB7",
    navbarHeight: 60,
    navbarCoverContent: false,
    supportedPayType: ["ecpay", "ecpay_cvs"],
    supportedDeliveyMethod: ["post", "Home"],
    supportedCVSType: ["seven_eleven"]
  },

  data: {
    localProductSearchFields: ["name", "description"]
  },

  "Components/Footer": {},

  "Components/Navbar": {
    background: "white",
    altBackground: "white",
    textColor: "#0A6FB7",
    textShadow: "2px 2px #ccc",
    breakpoint: 800
  },

  "Components/Lightbox": {
    css: ``,
    thumbnailCss: `
      width: 70px;
      height: 70px;
    `
  },

  "Components/OrderList": {
    css: ``
  },

  "Components/AddToCartButton": {
    css: `
    width:auto;

    & button {
      display: flex;
      justify-content:center;
      align-items:center;

      border: 1px solid #0A6FB7;
      padding: 3px 8px;
      border-radius: 17px;

      max-width: 166px;
      min-width: 100px;
      width: 100%;
      min-height: 34px;

      background-color: white;
      color: #0A6FB7;
    }
    `
  },

  "Components/FilterPanel": {
    breakPointTablet: 1024,
    breakPointPhone: 500
  },

  "Component/SortingGroup": {
    color: "gray",
    articlesOptions: [
      { label: "發表日期", value: "created" },
      { label: "更新日期(新 → 舊)", value: "-updated" },
      { label: "更新日期(舊 → 新)", value: "+updated" }
    ],
    productsOptions: [
      { label: "上市日期", value: "created" },
      { label: "優惠價(高 → 低)", value: "-price" },
      { label: "優惠價(低 → 高)", value: "+price" },
      { label: "更新日期(新 → 舊)", value: "-updated" },
      { label: "更新日期(舊 → 新)", value: "+updated" }
    ],
    css: ``
  },

  "Component/SortOption": {
    color: "gray",
    css: ``
  },

  "Templates/LandingPage": {
    css: `

    `
  },

  "Templates/StoreDetailPage": {
    css: `
    `
  },

  "Templates/OrderDetailPage": {
    css: ``
  },

  "Templates/CartPage": {
    css: ``
  },

  "Templates/ProductListingPage": {
    layoutType: "grid",
    itemLimit: 12,
    breadcrumbs: true,
    searching: true,
    css: `
      & > .products-content {
      }
    `
  },

  "Templates/ArticleListingPage": {
    layoutType: "grid",
    itemLimit: 4,
    breadcrumbs: true,
    css: ``
  },

  "Components/ProductGridItem": {
    showAveragePriceWhenBuyXGetYFree: true,
    themeColor: "brown",
    stars: 5
  }
};

module.exports = AppConfig;
