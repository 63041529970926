import React, { Component } from "react";
import "../index.css";
import styled from "styled-components";
import MuiWrapper from "../MuiWrapper";
import AppConfig from "../Config";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Delighters from "../Components/ReactDelighters";
import { Helmet } from "react-helmet";

class Layout extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let {
      location: { pathname }
    } = this.props;
    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = !AppConfig.global.navbarCoverContent;

    if (pathname === "/") {
      withPaddingTop = false;
    }

    return (
      <Delighters>
        <MuiWrapper>
          <Layout.Wrapper withPaddingTop={withPaddingTop}>
            <Helmet>
              <title>WantCard 享受旅遊</title>
              <meta name="description" content="" />
              <link rel="shortcut icon" href="/images/wantcard-logo.png" />
            </Helmet>

            <Navbar passBreakpoint={passBreakpoint || withPaddingTop} />

            <Layout.Content>{this.props.children}</Layout.Content>

            <Footer pathname={pathname} />
          </Layout.Wrapper>
        </MuiWrapper>
      </Delighters>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

Layout.Wrapper = styled.div`
  min-height: calc(100vh);
  width: 100%;
  overflow-x: hidden;
  background-color: white;
  padding-top: ${props =>
    props.withPaddingTop ? AppConfig.global.navbarHeight : 0}px;
  display: flex;
  flex-direction: column;
`;

Layout.Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

export default Layout;
