import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Link from "../Link";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../Widget";
import * as Icon from "../Icon";
import Image from "../Image";
import AppConfig from "../../Config";
import Login from "../Login";
import ContactUsDialog from "../ContactUsDialog";

const {
  background,
  altBackground,
  textColor,
  textShadow,
  breakpoint
} = AppConfig["Components/Navbar"];
const height = AppConfig.global.navbarHeight;
const themeColor = AppConfig.global.themeColor;

class LinkWrapper extends React.Component {
  render() {
    return (
      <StyleLink>
        {this.props.icon}
        <div className="text"> {this.props.text} </div>
      </StyleLink>
    );
  }
}

const StyleLink = styled.div`
  display: flex;
  align-items: center;
  & .text {
    color: ${themeColor};
    text-shadow: none;
    font-size: 0.8em;
    margin-left: 5px;
  }
`;

const navItems = [
  {
    children: (
      <LinkWrapper
        icon={<Icon.Wifi color={themeColor} size={18} />}
        text="全球上網"
      />
    ),
    url: "/products/simCard"
  },
  {
    children: (
      <LinkWrapper
        icon={<Icon.Airplane color={themeColor} size={18} />}
        text="旅行小物"
      />
    ),
    url: "/products/travelAttachment"
  },
  {
    children: (
      <LinkWrapper
        icon={<Icon.LibraryBooks color={themeColor} size={18} />}
        text="好文推薦"
      />
    ),
    url: "/blogs"
  },
  {
    children: (
      <LinkWrapper
        icon={<Icon.Email color={themeColor} size={18} />}
        text="聯絡我們"
      />
    ),
    url: "/"
  },
  {
    children: (
      <LinkWrapper
        icon={<Icon.Person color={themeColor} size={18} />}
        text="會員中心"
      />
    ),
    url: "/me"
  }
];

class Navbar extends React.Component {
  state = {
    openMenu: false,
    openLoginDialog: false,
    openContactUsDialog: false,
    pathname: "/"
  };

  render() {
    let { passBreakpoint, navActions } = this.props;
    let { openLoginDialog, openContactUsDialog, pathname } = this.state;

    return (
      <Style.Wrapper passBreakpoint={passBreakpoint}>
        <Style.Content>
          <Link to="/" className="site-title">
            <ImageWrapper>
              <Image src="../images/wantcard-logo.png" background />
            </ImageWrapper>
          </Link>

          {this._smallScreenNavBar()}
          {this._largeScreenNavBar()}
        </Style.Content>
        {openLoginDialog && (
          <Login.Dialog
            pathname={pathname}
            onClose={() => this.setState({ openLoginDialog: false })}
            onRedirect={() => navActions.push(pathname)}
          />
        )}
        {openContactUsDialog && (
          <ContactUsDialog
            open={openContactUsDialog}
            onClose={() => this.setState({ openContactUsDialog: false })}
          />
        )}
      </Style.Wrapper>
    );
  }

  _smallScreenNavBar() {
    let { profile, cartData } = this.props;
    const toggleMenuState = () =>
      this.setState({ openMenu: !this.state.openMenu });

    return (
      <Style.SmallScreenWrapper breakpoint={breakpoint}>
        <Widget.IconButton onClick={toggleMenuState}>
          <Icon.Apps color={themeColor} />
        </Widget.IconButton>

        <Style.SmallScreenMenu
          open={this.state.openMenu}
          onClick={toggleMenuState}
        >
          <div className="container">
            {[
              { children: <Icon.Home color="white" />, url: "/" },
              ...navItems
            ].map(({ children, url }, idx) => {
              if (idx === 4) {
                return (
                  <div
                    key={"small-nav-item" + idx}
                    className="nav-item"
                    onClick={() => this.setState({ openContactUsDialog: true })}
                  >
                    {children}
                  </div>
                );
              }

              return (
                <div
                  key={"small-nav-item" + idx}
                  to={url}
                  className="nav-item"
                  onClick={() => this._needToLogin(url)}
                >
                  {children}
                </div>
              );
            })}

            <div
              className="nav-item"
              onClick={() => this._needToLogin("/cart")}
            >
              <CartButton>
                <LinkWrapper
                  icon={<Icon.ShoppingCart color={themeColor} size={18} />}
                  text="CART"
                />
                <div className="number">
                  {profile ? (cartData ? cartData.items.length : 0) : 0}
                </div>
              </CartButton>
            </div>
          </div>
        </Style.SmallScreenMenu>
      </Style.SmallScreenWrapper>
    );
  }

  _largeScreenNavBar() {
    let { profile, cartData } = this.props;

    return (
      <Style.LargeScreenWrapper breakpoint={breakpoint}>
        {navItems.map(({ children, url }, idx) => {
          if (idx === 3) {
            return (
              <div
                key={"large-nav-item" + idx}
                className="nav-item"
                onClick={() => this.setState({ openContactUsDialog: true })}
              >
                {children}
              </div>
            );
          }

          return (
            <div
              key={"large-nav-item" + idx}
              className="nav-item"
              onClick={() => this._needToLogin(url)}
            >
              {children}
            </div>
          );
        })}

        <div className="nav-item" onClick={() => this._needToLogin("/cart")}>
          <CartButton>
            <LinkWrapper
              icon={<Icon.ShoppingCart color={themeColor} size={18} />}
              text="CART"
            />
            <div className="number">
              {profile ? (cartData ? cartData.items.length : 0) : 0}
            </div>
          </CartButton>
        </div>
      </Style.LargeScreenWrapper>
    );
  }

  _needToLogin = url => {
    let { navActions, profile } = this.props;

    if (url === "/me" && !profile) {
      return this.setState({ openLoginDialog: true, pathname: url });
    }

    if (url === "/cart" && !profile) {
      return this.setState({ openLoginDialog: true, pathname: url });
    }

    return navActions.push(url);
  };
}

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 10px 5%;
    height: ${height}px;
    z-index: 10;
    border-bottom: 1px solid ${themeColor};
    background: ${props => (props.passBreakpoint ? altBackground : background)};
    transition: background 200ms ease-in-out;
  `,

  Content: styled.div`
    margin: 0 auto;
    max-width: 1024px;
    height: 100%;
    display: flex;
    align-items: center;

    & > .site-title {
      color: ${textColor};
      text-shadow: ${textShadow};
      text-decoration: none;
      font-size: 30px;
    }
  `,

  LargeScreenWrapper: styled.div`
    @media screen and (max-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & .nav-item {
      cursor: pointer;
      margin-right: 15px;
      color: ${textColor};
      text-decoration: none;
      display: flex;
      align-items: center;

      & .number {
        margin-left: 5px;
        border-radius: 45%;
        padding: 2px 6px;
        background: #f8b82a;
        font-size: 12px;
        color: white;
      }
    }

    & .nav-item :last-child {
      margin-right: 0px;
    }
  `,

  SmallScreenWrapper: styled.div`
    @media screen and (min-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & .number {
      margin-left: 4px;
      border-radius: 45%;
      padding: 2px 6px;
      background: #f8b82a;
      font-size: 12px;
      color: white;
    }
  `,

  SmallScreenMenu: styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${props => (props.open ? "0" : "-100vw")};
    background-color: ${props =>
      props.open ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};
    transition: background-color 250ms linear,
      left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
    z-index: 5;

    & > .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding-top: 30px;

      & > .nav-item {
        margin-bottom: 32px;
        color: ${textColor};
        text-decoration: none;

        :last-child {
          margin-bottom: 0px;
        }
      }
    }
  `
};
const ImageWrapper = styled.div`
  height: ${height}px;
  width: ${(height * 1063) / 945}px;
`;

const CartButton = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid #0a6fb7;
  border-radius: 17px;
  padding: 5px;
  min-width: 110px;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    cartData: Selectors.getCartData(state)
  }),
  ActionCreator
)(Navbar);
