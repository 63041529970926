import React, { Component } from "react";
import styled from "styled-components";
import Link from "../Link";
import * as Widget2 from "../Widget2";
import * as Icon from "../Icon";
import ContactUsDialog from "../ContactUsDialog";

let countries = [
  { display: "日本", link: "japan" },
  { display: "韓國", link: "korea" },
  { display: "中國", link: "china" },
  { display: "香港", link: "hongkong" },
  { display: "澳門", link: "macao" },
  { display: "泰國", link: "thailand" },
  { display: "越南", link: "vietnam" },
  { display: "澳洲", link: "australia" },
  { display: "美國", link: "america" },
  { display: "加拿大", link: "canada" },
  { display: "菲律賓", link: "philippines" },
  { display: "俄羅斯", link: "russia" },
  { display: "新加坡", link: "singapore" },
  { display: "馬來西亞", link: "malaysian" },
  { display: "其他國家", link: "others" }
];

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false
    };
  }

  render() {
    let { extraStyle = "", pathname } = this.props;
    let { showDialog } = this.state;

    if (
      pathname === "/cart/" ||
      pathname === "/checkout/" ||
      pathname === "/order/"
    ) {
      return null;
    }

    return (
      <Wrapper extraStyle={extraStyle}>
        <div className="top">
          <AreaSeg>
            <div className="title">提供服務地區</div>
            <div className="item-container">
              {countries.map((country, i) => (
                <div className="item" key={"country" + i}>
                  <Link
                    to={`products/simCard-${country.link}`}
                    extraStyle={{
                      marginTop: 20,
                      color: "#505050"
                    }}
                  >
                    {country.display}
                  </Link>
                </div>
              ))}
            </div>
          </AreaSeg>

          <InfoSeg>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="title">使用方法</div>
              <div className="info-container">
                <div className="info">
                  <Link
                    to="/instruction"
                    extraStyle={{
                      marginTop: 20,
                      color: "#505050"
                    }}
                  >
                    使用說明
                  </Link>
                </div>
                <div className="info">
                  <Link
                    to="/faq"
                    extraStyle={{
                      marginTop: 20,
                      color: "#505050"
                    }}
                  >
                    FAQs
                  </Link>
                </div>
              </div>
            </div>
          </InfoSeg>

          <InfoSeg>
            <div style={{ display: "flex" }}>
              <div className="title">聯絡我們</div>
            </div>
            <div className="info-container">
              <div className="info">
                <Icon.LocalPhone size={18} css="margin-right:5px;" />
                (02)2508-2435
              </div>
              <div className="info">
                <Icon.Email size={18} css="margin-right:5px;" />
                wantcard@gmail.com
              </div>
              <div className="info">
                <Icon.AccessTime
                  size={18}
                  css="margin-right:5px; vertical-align: top;"
                />
                <div style={{ display: "inline-block" }}>
                  週一至週五 9:00 - 18:00
                  <br />
                  <span style={{ fontSize: "0.8em" }} css="margin-right:5px;">
                    (不含國定假日及例假日)
                  </span>
                </div>
              </div>
              {/* <div className="info">
                <Icon.Facebook size={18} css="margin-right:5px;" />
                wantcard
              </div>
              <div className="info">
                <Icon.Facebook size={18} css="margin-right:5px;" />
                wantcard
              </div> */}
              <div
                className="info"
                style={{
                  textDecoration: "underline",
                  color: "#505050",
                  cursor: "pointer"
                }}
                onClick={() => this.setState({ showDialog: true })}
              >
                填寫表單
              </div>
            </div>
          </InfoSeg>
        </div>

        <FooterBottom>
          <div style={{ display: "flex" }}>
            <div style={{ margin: "0 20px" }}>
              <Link
                to="/privacy-policy"
                extraStyle={{
                  marginTop: 20,
                  textDecoration: "none",
                  color: "#505050"
                }}
              >
                隱私權政策
              </Link>
            </div>
            <div style={{ margin: "0 20px" }}>
              <Link
                to="/service-terms"
                extraStyle={{
                  marginTop: 20,
                  textDecoration: "none",
                  color: "#505050"
                }}
              >
                使用者條款
              </Link>
            </div>
          </div>
          <div className="copy-right">Copyright © 2019 WANTCARD版權所有</div>
        </FooterBottom>
        {showDialog && (
          <ContactUsDialog
            open={showDialog}
            onClose={() => this.setState({ showDialog: false })}
          />
        )}
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: #eee;
  box-shadow: 0px 2px 8px -1px grey;
  width: 100vw;
  color: #3e3e3e;
  display: flex;
  flex-direction: column;

  & > .top {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;

    & > .top {
      flex-direction: column;
      align-items: center;
    }
  }
  ${props => props.extraStyle};
`;

const AreaSeg = styled.div`
  width: 230px;
  padding: 30px 20px;
  font-size: 0.9em;

  & .title {
    font-size: 1.1em;
    font-weight: 600;
    color: #16233d;
    margin: 0 20px 20px 0;
  }

  & > .item-container {
    display: flex;
    flex-flow: row wrap;
    max-width: 150px;
    width: 100%;
    & > .item {
      flex: 1 0 50%;
      margin-bottom: 10px;
    }
  }
`;

const InfoSeg = styled.div`
  min-width: 230px;
  padding: 30px 20px;
  font-size: 0.9em;
  & .title {
    font-size: 1.1em;
    font-weight: 600;
    color: #16233d;
    margin: 0 20px 20px 0;
  }

  & .info-container {
    display: flex;
    flex-flow: column;
    & > .info {
      margin-bottom: 10px;
    }
  }
`;

const FooterBottom = styled.div`
  width: 100%;
  font-size: 0.8em;
  border-top: solid 2px #0a6fb7;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  & > .copy-right {
    font-size: 0.4em;
  }
`;

export default Footer;
