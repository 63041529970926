import React, { Component } from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget2";
import * as Icon from "./Icon";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class ContactUsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        is_member: false,
        account: "",
        name: "",
        phone: "",
        email: "",
        company_name: "",
        company_number: "",
        type: null,
        message: ""
      },
      showSuccessMsg: false,
      showSpinner: false,
      hint: ""
    };
  }

  render() {
    let { open, onClose } = this.props;
    let { data, showSuccessMsg, showSpinner, hint } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="close" onClick={onClose}>
              <Icon.Close size={24} color="#0A6FB7" />
            </div>

            <div className="content">
              <div className="title">聯絡我們</div>
              <div className="desp">
                您可使用通訊軟體與我們聯絡或填寫下方表單將有專人為您服務。
              </div>

              {!showSuccessMsg ? (
                <div className="frame-container">
                  <div className="form-container">
                    <Widget.Row
                      css={`
                        width: 100%;
                        @media screen and (max-width: 550px) {
                          flex-wrap: wrap;
                        }
                      `}
                    >
                      <Widget.FormField css="margin-bottom: 40px;">
                        <span className="label">是否為會員</span>
                        <Widget.Row justify="center" css="flex: 1;">
                          <div
                            className="checkbox-container"
                            onClick={() =>
                              this.setState({
                                data: { ...data, is_member: true }
                              })
                            }
                          >
                            {data.is_member ? (
                              <Icon.CheckBox size={20} color="#0A6FB7" />
                            ) : (
                              <Icon.CheckBoxOutlineBlank
                                size={20}
                                color="#0A6FB7"
                              />
                            )}
                            <span className="label">是</span>
                          </div>
                          <div
                            className="checkbox-container"
                            onClick={() =>
                              this.setState({
                                data: { ...data, is_member: false }
                              })
                            }
                          >
                            {data.is_member ? (
                              <Icon.CheckBoxOutlineBlank
                                size={20}
                                color="#0A6FB7"
                              />
                            ) : (
                              <Icon.CheckBox size={20} color="#0A6FB7" />
                            )}
                            <span className="label">否</span>
                          </div>
                        </Widget.Row>
                      </Widget.FormField>
                      <Widget.FormField css="margin-bottom: 40px;">
                        <span className="label">會員帳號</span>
                        <input
                          value={data.account}
                          onChange={e =>
                            this.setState({
                              data: { ...data, account: e.target.value }
                            })
                          }
                        />
                      </Widget.FormField>
                    </Widget.Row>

                    <Widget.FormField css="margin-bottom: 40px;">
                      <span className="star">*</span>
                      <span className="label">問題類別</span>
                      <select
                        value={data.type}
                        onChange={e =>
                          this.setState({
                            data: { ...data, type: e.target.value },
                            hint: ""
                          })
                        }
                      >
                        <option disabled selected="selected">
                          請選擇
                        </option>
                        <option value="usage">如何操作商品</option>
                        <option value="shipping">商品寄送錯誤</option>
                        <option value="broken">商品無法使用</option>
                        <option value="speed">商品速度異常</option>
                        <option value="other">其他</option>
                      </select>
                    </Widget.FormField>

                    <Widget.Row
                      css={`
                        width: 100%;
                        @media screen and (max-width: 550px) {
                          flex-wrap: wrap;
                        }
                      `}
                    >
                      <Widget.FormField
                        css={`
                          margin-right: 5px;
                          margin-bottom: 40px;
                          @media screen and (max-width: 550px) {
                            margin-right: 0px;
                          }
                        `}
                      >
                        <span className="star">*</span>
                        <span className="label">姓名</span>
                        <input
                          value={data.name}
                          onChange={e =>
                            this.setState({
                              data: { ...data, name: e.target.value },
                              hint: ""
                            })
                          }
                        />
                      </Widget.FormField>
                      <Widget.FormField
                        css={`
                          margin-bottom: 40px;
                        `}
                      >
                        <span className="star">*</span>
                        <span className="label">電話</span>
                        <input
                          value={data.phone}
                          onChange={e =>
                            this.setState({
                              data: { ...data, phone: e.target.value },
                              hint: ""
                            })
                          }
                        />
                      </Widget.FormField>
                    </Widget.Row>

                    <Widget.FormField css="margin-bottom: 40px;">
                      <span className="label">E-mail</span>
                      <input
                        value={data.email}
                        onChange={e =>
                          this.setState({
                            data: { ...data, email: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 40px;">
                      <span className="label">公司名稱</span>
                      <input
                        value={data.company_name}
                        onChange={e =>
                          this.setState({
                            data: { ...data, company_name: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 40px;">
                      <span className="label">公司統一編號</span>
                      <input
                        value={data.company_number}
                        onChange={e =>
                          this.setState({
                            data: { ...data, company_number: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 40px;">
                      <span className="label">意見</span>
                      <textarea
                        value={data.message}
                        onChange={e =>
                          this.setState({
                            data: { ...data, message: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>
                  <div className="button-bar">
                    {hint && <div className="hint">{hint}</div>}
                    {showSpinner ? (
                      <Widget.Spinner />
                    ) : (
                      <button onClick={this._submit}>送出</button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="frame-container">
                  <div className="hint">送出成功！我們會盡快與您聯繫。</div>
                </div>
              )}
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _submit = () => {
    let { appActions } = this.props;
    let { data } = this.state;

    if (data.type === null) {
      this.setState({ hint: "問題類別為必填！" });
      return;
    } else if (data.name === "") {
      this.setState({ hint: "姓名為必填！" });
      return;
    } else if (data.phone === "") {
      this.setState({ hint: "電話為必填！" });
      return;
    }

    this.setState({ showSpinner: true });
    delay(1000)
      .then(() => appActions.createContactUsMessage(data))
      .then(() => this.setState({ showSuccessMsg: true }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

export default connect(
  null,
  ActionCreator
)(ContactUsDialog);
