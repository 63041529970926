import styled from "styled-components";
import * as Css from "../Css";

export const Dialog = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translateX(-100vw);
  transition: 0s ease-in transform 0.5s;

  &.active {
    transform: translateX(0);
    transition: 0s ease-in transform 0s;
  }

  & .backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: ${props => (props.open ? 1 : 0)};
    transition: 0.2s ease-in opacity;
  }

  & .container {
    background-color: white;
    width: 100%;
    max-width: 715px;
    min-height: 200px;
    max-height: calc(100% - 60px);
    border-radius: 5px;
    z-index: 1001;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;

    & > .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    & > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      width: 100%;

      & .title {
        margin-bottom: 16px;
        text-align: center;
        letter-spacing: 1.43px;

        font-weight: 500;
        font-size: 20px;
        color: #0a6fb7;
      }

      & .desp {
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
        letter-spacing: 1px;
        font-size: 14px;
        color: #16233d;
      }

      & .frame-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-y: auto;

        border-radius: 3px;
        border: 1px solid lightgray;
        padding: 30px 20px;
        max-width: 616px;
        width: 100%;
        min-height: 150px;
      }

      & .form-container {
        max-width: 450px;
        width: 100%;
        overflow-y: auto;
      }
    }

    & .button-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 450px;
      width: 100%;

      & > button {
        border-radius: 17px;
        padding: 7px;
        min-width: 120px;
        height: 34px;
        background: #0a6fb7;

        letter-spacing: 1px;
        text-align: center;
        font-size: 14px;
        color: white;

        :focus {
          outline: none;
        }
      }
    }

    & .hint {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 10px 0px;
      font-size: 14px;
      color: #0a6fb7;
    }

    @media screen and (max-width: 700px) {
      min-width: initial;
      padding: 40px 60px;
    }
    @media screen and (max-width: 550px) {
      padding: 25px;

      & > .content {
        & .frame-container {
          padding: 40px 20px 20px;
        }
      }
    }
  }
`;

export const FormField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & .star {
    margin-right: 3px;
    color: red;
  }

  & .label {
    margin-right: 10px;
    min-width: 30px;
    font-size: 14px;
    color: #16233d;
  }

  & .checkbox-container {
    display: flex;
    align-items: center;
  }

  & select {
    flex: 1;
    background-color: white;
    height: 38px;
    border: 1px solid #16233d;
    & :focus {
      outline: none;
    }
  }

  & input {
    flex: 1;
    border: 0px;
    border-bottom: 1px solid #16233d;
    padding: 4px 2px;
    font-size: 14px;
    & :focus {
      outline: none;
    }
  }

  & textarea {
    flex: 1;
    resize: none;
    border: 0px;
    border-bottom: 1px solid #16233d;

    font-size: 14px;

    & :focus {
      outline: none;
    }
  }

  ${props => props.css || ""};
`;

export const BasicButton = styled.button`
  min-width: 116px;
  box-shadow: 0 2px 6px 0 rgba(29, 64, 117, 0.5);
  border: 0px;
  padding: 10px;
  background: #0075c1;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  :focus {
    outline: none;
  }
  ${props => props.css};
`;

export const Absolute = styled.div`
  ${Css.Absolute} ${props => props.css};
`;

export const Figure = styled.figure`
  ${Css.Background} ${props => props.css};
`;

export const Center = styled.div`
  ${Css.Center} ${props => props.css};
`;

export const MaxWidth = styled.div`
  max-width: ${props => props.width}px;
  margin: ${props => props.margin || "0 auto"};
  ${props => props.css};
`;

export const Container = styled.div`
  ${props => props.css};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  ${props => props.css};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  ${props => props.css};
`;

export const Button = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${props => props.color || "teal"};
  color: ${props => props.textColor || "#fff"};
  font-size: ${props => props.fontSize || "20px"};

  & :hover {
    font-weight: 900;
    background: ${props => props.hoverColor || props.color || "teal"};
  }

  & :focus {
    background: ${props => props.focusColor || props.color || "teal"};
    outline: none;
  }

  & :active {
    transform: scale(0.88);
  }

  & :disabled {
    background: ${props => props.disableColor || "#ccc"};
    color: ${props => props.disableTextColor || "#666"};
  }

  ${props => props.css || ""};
`;

export const FlatButton = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: font-weight 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  color: ${props => props.color || "black"};
  font-size: ${props => props.fontSize || "16px"};

  & :hover {
    transform: scale(1.05);
    color: ${props => props.color || "black"};
  }

  & :focus {
    color: ${props => props.color || "black"};
    outline: none;
  }

  & :active {
    transform: scale(0.88);
  }

  & :disabled {
    color: ${props => props.color || "#ccc"};
  }

  ${props => props.css || ""};
`;

export const Spinner = styled.div`
  border: 3px solid white;
  border-top-color: lightgray;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  animation: spin 1s linear infinite;

  ${props => props.css || ""};

  @keyframes spin {
  to {
    transform: rotate(360deg);
  }
`;
